<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
   import formkitClasses from '~/const/formkitClasses';
   const { surveyQuestion, lastQuestionIndex } = storeToRefs(useSurveyStore());
   const { submitSurvey, nextStepSurvey } = useSurveyStore();
   const emit = defineEmits<{
      (event: 'close'): void;
   }>();

   const lastQuestion = computed(
      () =>
         question.value?.id ==
         surveyQuestion.value?.questions?.[
            surveyQuestion.value?.questions.length - 1
         ]?.id
   );
   const question = computed(
      () => surveyQuestion.value?.questions?.[lastQuestionIndex.value]
   );

   const submitAnswer = async () => {
      const isLast = lastQuestion.value;
      const answerBody =
         question.value?.type === 'text' ?
            { answer_text: answer.value }
         :  {
               answer_ids:
                  question.value?.type === 'multiple' ?
                     (answerArray.value as any)
                  :  [answer.value],
            };

      if (question.value?.id) {
         await submitSurvey(question.value?.id, answerBody);
      }
      if (isLast) {
         emit('close');
      } else {
         await nextStepSurvey();
      }
   };
   const answer = ref<string>('');
   const answerArray = ref<string[]>([]);
   const answers = computed(() => {
      return (
         question.value?.answers?.map((item) => ({
            name: t(item.answer),
            value: item.id,
         })) || []
      );
   });
   const setAnswer = () => {
      if (question.value?.responses?.length) {
         if (question.value?.type == 'multiple') {
            answerArray.value = question.value?.responses?.map(
               (item) => item.answer_id
            );
         } else if (question.value?.type == 'text') {
            answer.value = question.value?.responses?.[0]?.answer_text || '';
         } else {
            answer.value = question.value?.responses?.[0]?.answer_id || '';
         }
      } else {
         answerArray.value = [];
         answer.value = '';
      }
   };
   onMounted(() => {
      setAnswer();
   });

   const isDisabled = computed(() => {
      if (!question?.value?.required) {
         return false;
      } else if (question.value?.type == 'multiple') {
         return !answerArray.value.length;
      } else {
         return !answer.value;
      }
   });

   const { t } = useI18n();
</script>
<template>
   <div class="flex flex-col h-[50dvh] overflow-y-auto mt-6">
      
<FormKitLazyProvider config-file="true">
<p class="text-gray-80 text-sm">{{ t(`${question?.question}`) }}</p>
      <div class="my-6">
         <div v-if="question?.type == 'multiple' || question?.type == 'single'">
            <p class="text-xs font-semibold text-gray-200 mb-2">
               {{ t('survey.choose_option') }}
               <span v-if="question?.type == 'multiple'">
                  {{ t('survey.multiple') }}
               </span>
            </p>
            <RegisterSharedCardList
               v-if="question?.type == 'multiple'"
               :data="answers"
               check-append
               hover-bg
               size="sm"
               :multiple="true"
               v-model:value="answerArray"
            />
            <RegisterSharedCardList
               v-else
               :data="answers"
               check-append
               hover-bg
               size="sm"
               v-model:value="answer"
            />
         </div>
         <div v-if="question?.type == 'nps'" class="flex flex-col">
            <div
               class="flex gap-3 justify-center"
               v-if="question?.answers.length <= 5"
            >
               <div
                  v-for="i in question?.answers"
                  :class="
                     `${i.id}` == answer ?
                        'bg-primary-500 text-white'
                     :  'bg-white'
                  "
                  @click.prevent="answer = `${i.id}`"
                  class="w-16 h-20 border font-semibold cursor-pointer border-gray-25 rounded-lg flex justify-center items-center"
               >
                  {{ i.answer }}
               </div>
            </div>
            <div class="flex justify-center" v-else>
               <div
                  v-for="i in question?.answers"
                  :class="
                     `${i.id}` == answer ?
                        'bg-primary-500 text-white'
                     :  'bg-white'
                  "
                  @click.prevent="answer = `${i.id}`"
                  class="w-12 h-10 sm:h-12 font-semibold border cursor-pointer border-gray-25 rounded-lg flex justify-center items-center"
               >
                  {{ i.answer }}
               </div>
            </div>
            <div
               v-if="question?.meta?.nps"
               class="flex justify-between mt-3 text-sm text-gray-200"
               :class="
                  question?.answers?.length <= 5 ? ' sm:mx-20 ' : ' sm:mx-5'
               "
            >
               <p>{{ `${question?.meta?.nps?.highest_text}` }}</p>
               <p>{{ t(`${question?.meta?.nps?.lowest_text}`) }}</p>
            </div>
         </div>

         <div v-if="question?.type == 'text'" class="flex flex-col">
            <FormKit
               type="textarea"
               :placeholder="t('survey.improve_feedback')"
               name="Feedback"
               :classes="formkitClasses.textarea"
               v-model="answer"
               rows="10"
            ></FormKit>
         </div>
      </div>
      <div class="mt-auto">
         <BaseButton
            :disabled="isDisabled"
            @click.prevent="submitAnswer"
            block
            class="rounded-xl"
         >
            {{
               !lastQuestion ? t('survey.next') : t('survey.submit')
            }}</BaseButton
         >
      </div>
</FormKitLazyProvider>

   </div>
</template>
