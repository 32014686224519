<script setup lang="ts">
   import { onMounted } from 'vue';
   import { DefaultAssets } from '~/assets/DefaultAssets';
   const { getSurveyQuestion } = useSurveyStore();
   const { lastQuestionIndex, surveyQuestion } = storeToRefs(useSurveyStore());
   const { getSelectedTherapistInfo } = useClientInfo();

   const emit = defineEmits<{
      (event: 'close'): void;
   }>();

   const loading = ref(false);

   const getQuestion = async () => {
      loading.value = true;
      if (!surveyQuestion.value?.questions?.length) {
         await getSurveyQuestion();
      }

      loading.value = false;
   };
   onMounted(() => {
      getQuestion();
   });

   const { t } = useI18n();

   const closeDialog = async () => {
      emit('close');
      await getSelectedTherapistInfo();
   };
</script>
<template>
   <div v-if="loading" class="flex justify-center items-center min-h-96">
      <BaseLoading size="w-20 h-20" />
   </div>
   <div v-else>
      <div
         class="flex mx-8 sm:mx-20 mb-6 flex-col gap-3 text-center justify-center items-center"
      >
         <img
            :src="DefaultAssets.images.logo"
            class="w-32 md:w-36"
            :alt="`TalkTime Logo `"
         />
         <p class="font-medium text-lg">{{ t('survey.anonymous_survey') }}</p>
         <p class="text-xs text-gray-200">
            {{ t('survey.anonymous_survey_Description') }}
         </p>
      </div>

      <div
         class="shadow-top border-t border-gray-25/50 rounded-3xl m-1 px-6 py-8"
      >
         <ClientSurveySteps />
         <ClientSurveyQuestion :key="lastQuestionIndex" @close="closeDialog" />
      </div>
   </div>
</template>
<style scoped>
   .shadow-top {
      box-shadow: 0px -2px 6px -1px rgba(0, 0, 0, 0.1);
   }
</style>
